<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <!-- <div class="img-background">
        <img src="@/assets/images/Image-organic-shape.svg" alt="" class="img-organic-shape">
        <img src="@/assets/images/Image-leaf.svg" alt="" class="img-leaf">
        <img src="@/assets/images/Image-dip-homepage.svg" alt="" class="img-dip-homepage">
      </div> -->
      <div class="row">
        <div class="left-v3 col-md-6 d-flex justify-content-center">
          <div class="form-login-v3">
            <p class="title-form-v3">Sign In</p>
            <p class="sub-title-form-v3">Don’t have an account? <span><router-link tag="a" to="/signup" class="blue-text-v3" href="">Sign Up</router-link></span></p>
            <b-form action="" @submit.prevent="tryToLogIn">
              <div class="form-input">
                <b-form-group>
                  <b-form-input
                    :state="statePassword"
                    v-model="email"
                    type="email"
                    class="form-control input-box-text-v3"
                    placeholder="Email"></b-form-input>
                </b-form-group>
              </div>
              <div class="form-input">
                <b-form-group>
                  <b-form-input
                   :state="statePassword"
                    v-model="password"
                    :type="passwordState"
                    class="form-control input-box-v3"
                    placeholder="Password"></b-form-input>
                    <b-form-invalid-feedback
                      v-html="invalidPassword"
                    >
                    </b-form-invalid-feedback>
                  <img v-if="passwordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showPassword()">
                  <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()">
                </b-form-group>
              </div>
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                value="accepted"
                unchecked-value="not_accepted"
              >
                <p class="sub-title-form-v3 margin-top-check-v3">Remember me</p>
              </b-form-checkbox>
              <div class="d-flex justify-content-between align-items-center">
                <router-link tag="a" to="/forgot-password" class="sub-title-form-v3"><span class="blue-text-v3">Forgot Password</span></router-link>
                <button :class="loading || !email.length || !password.length ? 'btn base-btn' : 'active btn base-btn'" :disabled="loading || !email.length || !password.length">
                  <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> 
                  <span class="sub-title-form-v3"><span :class="loading || !email.length || !password.length ? '' : 'text-white'">Sign In</span></span>
                </button>
              </div>
            </b-form>
          </div>
        </div>
        <div class="right-v3 col-md-6">
          <div class="d-flex flex-column">
            <div class="box-title-login-v3">
              <p class="title-login-v3">Share, access, and collaborate on files <span>securely.</span></p>
            </div>
            <div class="img-background">
              <img src="@/assets/images/Image-organic-shape.svg" alt="" class="img-organic-shape">
              <img src="@/assets/images/Image-leaf.svg" alt="" class="img-leaf">
              <img src="@/assets/images/Image-dip-homepage.svg" alt="" class="img-dip-homepage">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

export default {
  components: {
    Navbar
  },
  data() {
    return {
      email: '',
      password: '',
      passwordState: 'password',
      loading: false,
      tryingToLogIn: false,
      invalidEmail: "Your account or Email is incorrect.",
      stateEmail: true,
      invalidPassword: "Your account or password is incorrect.",
      statePassword: true,
      authError: null,
      isAuthError: false,
    }
  },
  page: {
    title: "Sign In",
    titleTemplate: '%s - Jaybod',
    meta: [
      { name: "description",
        content: "Sign in to your Jaybod cloud storage account to organize, share, and collaborate on files securely."
      }
    ]
  },
  methods: {
    ...authMethods,
    showPassword() {
      if(this.passwordState === 'password'){
        this.passwordState = 'text'
      } else {
        this.passwordState = 'password'
      }
    },
    tryToLogIn() {
      this.$ga.event(process.env.VUE_APP_GA_LOGIN_BTN_PAGE, 'actionx', 'labelx', 1)
      this.tryingToLogIn = true;
      sessionStorage.removeItem('invitationEmail')
      this.loading = true;
      if(this.email == ""){
        this.invalidEmail = "Please Fill Email Field.";
        this.stateEmail = false;
        this.loading = false;
        return false;
      }else{
        this.stateEmail=true;
        this.loading = false;
      }
      if(this.password == ""){
        this.invalidPassword = "Please Fill Password Field."
        this.statePassword = false;
        this.loading = false;
        return false;
      }else{
        this.statePassword = true;
        this.loading = false;
      }
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.logIn({
          email: this.email,
          password: this.password
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.loading = false;
            // Redirect to the originally requested page, or to the home page
            this.$store.commit('state/SET_STATE', 'storage')
            this.$store.commit("workgroup/SET_WORKGROUP",null)
            this.$store.commit("storage/DELETE_BREADCUMB",[]);
            this.$store.dispatch("storage/listing")
            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            );
          })
          .catch(error => {
            if(error.data.suspend){
              this.invalidEmail = "";
              this.stateEmail = false;
              this.tryingToLogIn = false;
              // this.invalidPassword = error ? error : ""
              this.invalidPassword = typeof error == 'object' ? `${error.display_message}. ${error.data.suspend.reason}` : "Something went wrong! Try again later.";
              this.statePassword = false;
              // this.authError = error ? error : "";
              this.authError = typeof error == 'object' ? "Something went wrong! Try again later." : error.data.display_message;
              this.isAuthError = true;
              this.loading = false;
            } else {
              this.invalidEmail = "";
              this.stateEmail = false;
              this.tryingToLogIn = false;
              // this.invalidPassword = error ? error : ""
              this.invalidPassword = typeof error == 'object' ? error.data.display_message : "Something went wrong! Try again later.";
              this.statePassword = false;
              // this.authError = error ? error : "";
              this.authError = typeof error == 'object' ? "Something went wrong! Try again later." : error.data.display_message;
              this.isAuthError = true;
              this.loading = false;
            }
          })
      );
    }
  },
  mounted() {
    this.$ga.event(process.env.VUE_APP_GA_LOGIN_PAGE, 'actionx', 'labelx', 1)
    if(this.$route.query.email != undefined){
      this.email = this.$route.query.email
    } else {
      if(sessionStorage.getItem('invitationEmail') != undefined){
        this.email = sessionStorage.getItem('invitationEmail')
      }
    }
  }
}
</script>

<style>
.invalid-feedback a{
  color: #D42020 !important;
}
#__BVID__11 div .is-valid{
    border: 1px solid #ced4da;
    background: #FFFFFF;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
#__BVID__11 div .is-valid:focus{
    border: 1px solid #ced4da;
    box-shadow: none;
}
#__BVID__13 div .is-valid{
    border: 1px solid #ced4da;
    background: #FFFFFF;
    border-radius: 4px;
    background-image: none !important;
    padding-right: 16px !important;
}
#__BVID__13 div .is-valid:focus{
    border: 1px solid #ced4da;
    box-shadow: none;
}
@media (max-width: 767px) {
    .nav-z-idx{
      height: 10vh;
    }
    .left-v3{
      display: block;
    }
    .right-v3{
      display: none;
    }
    .container-v3{
      background-color: #FFFFFF !important;
      height: calc(100vh - 10vh);
    }
    .form-login-v3{
      margin: 1em;
      /* margin-top: 30px; */
      /* background-color: #EFF3F5; */
    }
}
</style>
